import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class OfficialMembers extends APIBaseFunctions {
  static OfficialMembersService = class {
    private static OfficialMembersDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static async getOfficialMembers (extraParameter = '') {
      return http.get('/officials' + (extraParameter.length > 0 ? `?${extraParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/officials', data)
    }

    static update (id: string, data: any) {
      return http.put(`/officials/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/officials/${id}`)
    }
  }
}

export default new OfficialMembers()
